<template>
  <div class="account-settings-page px-5">
    <BackToSettings />
    <h1 class="text-h1 mb-8">Account Details</h1>

    <data-preparation-overlay v-if="isPageLoading" />

    <v-row v-else>
      <v-col
        cols="12"
        v-if="
          selectedSetting === null ||
          (selectedSetting != null && selectedSetting.key === 'account')
        "
      >
        <v-card outlined class="account-detail rounded-xl pa-8 py-6">
          <v-btn
            dark
            class="float-right"
            v-if="emailChanged"
            :disabled="this.$v.$invalid"
            @click="updateShopInfo()"
          >
            Save Changes
          </v-btn>
          <v-card-title class="text-h2">My Store Details</v-card-title>
          <v-row>
            <v-col cols="6">
              <v-card-text>
                <div class="text-h5 mb-3">Platform</div>
                <div
                  v-if="currentShop.platform === 'woocommerce'"
                  class="overflow-hidden"
                >
                  <span class="float-left mr-5">
                    <v-img
                      max-height="25"
                      max-width="25"
                      src="../../../assets/images/woo_logo.png"
                      class="text-center platform-image"
                    ></v-img>
                  </span>
                  <span class="float-left"> WooCommerce </span>
                </div>
                <div
                  v-else-if="currentShop.platform === 'shopify'"
                  class="overflow-hidden"
                >
                  <span class="float-left mr-5">
                    <v-img
                      max-height="25"
                      max-width="25"
                      src="../../../assets/images/shopify_logo.png"
                    ></v-img>
                  </span>
                  <span class="float-left"> Shopify </span>
                </div>
                <div v-else>
                  {{ currentShop.platform }}
                </div>
              </v-card-text>
            </v-col>
            <v-col cols="6">
              <v-card-text>
                <div class="text-h5 mb-3">Store Type</div>
                <div class="text-capitalize">{{ currentShop.type }} Store</div>
              </v-card-text>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-card-text>
                <div class="text-h5 mb-3">Store Name</div>
                <div>{{ currentShop.store_domain }}</div>
              </v-card-text>
            </v-col>
            <v-col cols="6">
              <v-card-text>
                <div class="text-h5 mb-3">Joined Date</div>
                <div>
                  {{ currentShop.created_at | moment("MMMM DD, Y h:mm A") }}
                </div>
              </v-card-text>
            </v-col>
          </v-row>
          <v-divider class="mt-5 mb-5"></v-divider>
          <v-row>
            <v-col cols="12">
              <v-card-title class="text-h2">Syncio Unique Key</v-card-title>
              <v-card-text class="text-h3 d-flex align-center">
                <font-awesome-icon :icon="['fas', 'key']" class="mr-5" />
                <span>{{ currentShop.identifier }}</span>
                <input
                  type="hidden"
                  ref="syncioKey"
                  :value="currentShop.identifier"
                />
                <button
                  class="ml-5 copy-btn"
                  type="button"
                  v-clipboard:copy="currentShop.identifier"
                  v-clipboard:success="() => (copySuccess = true)"
                >
                  Copy
                </button>
              </v-card-text>
            </v-col>
          </v-row>
          <v-divider class="mt-5 mb-5"></v-divider>

          <!-- Woo Refresh API key -->
          <template v-if="currentShop.platform === 'woocommerce'">
            <v-row>
              <v-col cols="12">
                <v-card-title class="text-h2">API Key</v-card-title>
                <v-card-text class="text-h3">
                  <v-btn
                    class="btn btn-primary"
                    elevation="0"
                    @click="isRefreshAPIDialogVisible = true"
                    >Update</v-btn
                  >
                </v-card-text>
              </v-col>
            </v-row>
            <v-divider class="mt-5 mb-5"></v-divider>

            <v-overlay
              :value="isRefreshAPIDialogVisible"
              class="dialog dialog-disconnect"
            >
              <v-card light class="card-rounded not-a-link" width="625px">
                <v-toolbar height="71">
                  <v-container class="px-0 d-flex justify-space-between">
                    <v-card-title class="h3 pa-0">
                      Update Woocommerce store API key
                    </v-card-title>
                    <v-icon
                      size="30px"
                      color="#ffffff"
                      class="mt-1"
                      @click="isRefreshAPIDialogVisible = false"
                      >close
                    </v-icon>
                  </v-container>
                </v-toolbar>

                <div class="dialog-body pa-7">
                  <div class="text-left">
                    <h4 class="h4 mb-5">
                      Please provide us with a new API key to continue using
                      Syncio.
                    </h4>
                    <p>Instructions:</p>
                    <ul class="px-0 inside-decimal">
                      <li>
                        Go to Woocommerce -> Settings -> Advanced -> REST API
                        and click "Add key"
                      </li>
                      <li>
                        In the submit form, add a description, select a valid
                        user, and select the "Read/Write" permission.
                      </li>
                      <li>Click "Generate API key".</li>
                      <li>
                        Paste the generated Consumer key and secret key below.
                      </li>
                    </ul>
                  </div>

                  <v-form v-model="valid" class="mt-6">
                    <v-row>
                      <v-col cols="6" class="pb-0">
                        <v-text-field
                          dense
                          outlined
                          label="Consumer Key"
                          v-model="consumerKey"
                          :rules="rules"
                        />
                      </v-col>
                      <v-col cols="6" class="pb-0">
                        <v-text-field
                          dense
                          outlined
                          label="Consumer Secret Key"
                          v-model="consumerSecretKey"
                          :rules="rules"
                        />
                      </v-col>
                      <v-col cols="12" class="pt-0" v-if="keyValid">
                        <span class="error--text">{{ errorMessage }} </span>
                      </v-col>
                      <v-col cols="12" class="pt-0">
                        <v-btn
                          height="45px"
                          width="100%"
                          class="btn btn-primary mb-2"
                          @click="updateWooAPIKeyHandler"
                        >
                          Update
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </div>
              </v-card>
            </v-overlay>
          </template>

          <v-row>
            <v-col cols="12">
              <v-card-text v-if="currentShop.platform === 'shopify'">
                * For Shopify, please use Shopify admin page to uninstall
                Syncio.
              </v-card-text>
              <v-card-text v-else>
                <v-btn color="error" elevation="0" @click="toggleUninstall()">
                  Uninstall
                </v-btn>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="copySuccess"
      :timeout="timeout"
      top
      color="#0E3B4D"
      elevation="0"
    >
      {{ copyMessage }}
      <template v-slot:action="{ attrs }">
        <v-icon color="#fff" v-bind="attrs" @click="copySuccess = false"
          >close</v-icon
        >
      </template>
    </v-snackbar>

    <v-snackbar
      v-model="copySuccessWooApi"
      :timeout="timeout"
      top
      color="#0E3B4D"
      elevation="0"
    >
      {{ copyMessageWooApi }}
      <template v-slot:action="{ attrs }">
        <v-icon color="#fff" v-bind="attrs" @click="copySuccessWooApi = false"
          >close</v-icon
        >
      </template>
    </v-snackbar>
    <v-dialog v-model="uninstallIsOpen" width="500px">
      <v-card>
        <v-card-title class="text-h5 primary white--text">
          Confirm Uninstall
        </v-card-title>
        <v-spacer />
        <v-card-subtitle
          style="padding-top: 1rem"
          class="text-h6 font-weight-bold"
          >Are you sure you want to uninstall Syncio?
        </v-card-subtitle>
        <v-card-text class="text-body-1">
          You'll lose any store connections and product syncs, and will need to
          set these up again if you reinstall the app
        </v-card-text>
        <v-spacer />
        <v-card-text class="font-weight-bold" style="padding-top: 1rem">
          Type "uninstall" to confirm
          <v-text-field
            outlined
            placeholder="enter text"
            v-model="uninstallConfirmed"
            class="font-weight-regular"
          />
          {{
            isUninstalling
              ? "Uninstallation is now in progress, You will be logged out"
              : ""
          }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" outlined @click="toggleUninstall()">
            Cancel
          </v-btn>
          <v-btn
            color="error"
            elevation="0"
            @click="uninstallShopHandler()"
            :disabled="isUninstallDisabled"
          >
            Uninstall
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapState } from "vuex";
import DataPreparationOverlay from "../../components/DataPreparationOverlay.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import ShopApiService from "@/common/api/shop.service";
import {
  LOAD_CURRENT_CONFIGURATIONS,
  UPDATE_CURRENT_CONFIGURATIONS,
} from "@/store/actions.type";
import { required, email } from "vuelidate/lib/validators";
import axios from "axios";
import { target } from "vuelidate/lib/params";
import BackToSettings from "@/views/settings/BackToSettings.vue";

export default {
  name: "AccountSettingsPage",
  data: function () {
    return {
      newToggleValue: false,
      updatingShop: false,
      newEmail: null,
      selectedSetting: {
        name: "Account",
        key: "account",
      },
      currentAccountConfig: null,
      visibleDestinationSettings: [
        {
          name: "Account",
          key: "account",
        },
        {
          name: "Email Notifications",
          key: "email_notifications",
        },
      ],
      visibleSourceSettings: [
        {
          name: "Account",
          key: "account",
        },
      ],
      copyMessage: "Store Key copied to the clipboard!",
      copySuccess: false,
      timeout: 2000,
      isRefreshAPIDialogVisible: false,
      consumerKey: "",
      consumerSecretKey: "",
      rules: [(v) => !!v || "Field is required"],
      valid: false,
      copyMessageWooApi: "API key successfully updated.",
      copySuccessWooApi: false,
      errorMessage: "API key & Secret are not valid!",
      keyValid: false,
      uninstallIsOpen: false,
      uninstallConfirmed: "",
      isUninstalling: false,
    };
  },
  validations: {
    newEmail: { required, email },
  },
  created() {
    if (this.currentShop === null) {
      this.$store.dispatch("shop/init").finally(() => {
        this.newEmail = this.currentShop.email;
      });
    } else {
      this.newEmail = this.currentShop.email;
    }
    this.reloadAccountConfigs();
  },
  components: {
    FontAwesomeIcon,
    DataPreparationOverlay,
    BackToSettings,
  },
  computed: {
    ...mapState("configs", ["currentConfigs"]),
    ...mapState("auth", ["user", "user_id"]),
    ...mapState("shop", ["currentShop"]),
    checkEmailErrors() {
      const emailErrors = [];
      if (!this.$v.newEmail.$dirty) return emailErrors;
      !this.$v.newEmail.email && emailErrors.push("Must be valid e-mail");
      !this.$v.newEmail.required && emailErrors.push("E-mail is required");
      return emailErrors;
    },
    isUninstallDisabled() {
      return this.uninstallConfirmed !== "uninstall";
    },
    emailChanged() {
      return this.newEmail != this.currentShop.email;
    },
    isPageLoading() {
      return (
        this.currentShop === null ||
        (this.currentShop.type != "source" &&
          this.currentAccountConfig === null) ||
        typeof this.user.name === "undefined" ||
        this.updatingShop ||
        this.currentConfigs === null
      );
    },
    visibleSettings() {
      if (this.currentShop.type === "destination") {
        return this.visibleDestinationSettings;
      } else {
        return this.visibleSourceSettings;
      }
    },
  },
  watch: {
    newEmail(newValue) {
      if (newValue === null) {
        this.$store.dispatch(`shop/loadCurrentShop`);
      }
    },
  },
  methods: {
    target() {
      return target;
    },
    toggleUninstall() {
      this.uninstallIsOpen = !this.uninstallIsOpen;
    },

    async updateWooAPIKeyHandler() {
      try {
        const response = await axios
          .post("/wc/2020-01/update-token", {
            store_id: this.currentShop.id,
            consumer_key: this.consumerKey,
            consumer_secret: this.consumerSecretKey,
          })
          .then((data) => {
            this.isRefreshAPIDialogVisible = false;
            this.consumerKey = "";
            this.consumerSecretKey = "";
            // this.$router.push({name: "DashboardPage"});
            this.copySuccessWooApi = true;
          });
      } catch (error) {
        this.keyValid = true;
      }
    },
    reloadAccountConfigs() {
      return new Promise((resolve) => {
        this.$store
          .dispatch(`configs/${LOAD_CURRENT_CONFIGURATIONS}`, {
            currentUserId: this.user_id,
          })
          .then((data) => {
            if (data.success) {
              data.configurations.forEach((config) => {
                if (config.key === "product_update_notification") {
                  this.currentAccountConfig = config;
                  this.newToggleValue = config.is_active;
                }
              });
            }
          })
          .finally(() => {
            resolve();
          });
      });
    },
    updateShopInfo() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.updatingShop = true;
        ShopApiService.updateShopInfo(this.currentShop.id, {
          email: this.newEmail,
        }).finally(() => {
          this.$store.dispatch(`shop/loadCurrentShop`);
          this.updatingShop = false;
        });
      }
    },
    activateConfigBody(setting) {
      this.selectedSetting = setting;
    },
    saveEmailNotificationConfig() {
      this.updatingShop = true;
      this.$store
        .dispatch(`configs/${UPDATE_CURRENT_CONFIGURATIONS}`, {
          userId: this.user.id,
          newConfigSettings: [
            {
              name: "Account",
              configs: [
                {
                  key: "product_update_notification",
                  value: this.newToggleValue,
                },
              ],
            },
          ],
        })
        .finally(() => {
          this.reloadAccountConfigs().finally(() => {
            this.updatingShop = false;
          });
        });
    },
    async uninstallShopHandler() {
      await this.$store.dispatch("shop/uninstallShop", this.currentShop.id);
      this.isUninstalling = true;
      setTimeout(() => {
        this.$router.push({ name: "LogoutPage" });
      }, 2000);
    },
  },
};
</script>
<style lang="scss">
.account-settings-page {
  .account-detail {
    border-radius: 5px;
  }

  .v-list {
    background: transparent;

    .config-block {
      border: 1px solid #ccc;
      background-color: #fff;

      &:first-child {
        border-radius: 5px 5px 0px 0px;
      }

      &:last-child {
        border-radius: 0px 0px 5px 5px;
      }

      &.active {
        border-left: 5px solid #0e3b4d;
      }

      + .config-block {
        border-top: 0;
      }
    }
  }

  .copy-btn {
    border: 1px solid #0e3b4d;
    font-size: 14px;
    padding: 0px 12px;

    &:hover {
      background: #0e3b4d;
      color: #fff;
    }
  }

  .btn-primary {
    border-radius: 4px;

    &:disabled {
      background: rgba(14, 59, 77, 0.5) !important;
      color: #fff !important;
    }
  }

  .dialog-body {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
}

.inside-decimal {
  list-style: decimal inside;
  line-height: 28px;
}
</style>
